import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const VerifyEmailPage = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Extract the token from URL
        const token = new URLSearchParams(window.location.search).get('token');

        if (token) {
            // Send the token to the backend for verification
            fetch('http://localhost:3002/api/verify-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setMessage('Email verified successfully!');
                        navigate('/request-pending'); // Redirect to the Request Pending page
                    } else {
                        setMessage(data.message || 'Verification failed.');
                    }
                })
                .catch(() => {
                    setMessage('An error occurred. Please try again.');
                });
        } else {
            setMessage('Invalid verification link.');
        }
    }, [navigate]);

    return (
        <div>
            <h1>Verify Email</h1>
            <p>{message}</p>
        </div>
    );
};

export default VerifyEmailPage;
