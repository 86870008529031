import React, { useState, useEffect } from 'react';
import './AdminPortal.css';

type User = {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    organization: string;
    verified: boolean;
    approved: boolean;
    phoneNumber: string;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    role: string;
};

const AdminPortal = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);  // Store selected user
    const [showDetails, setShowDetails] = useState(false);

    // Fetch pending users from the backend
    useEffect(() => {
        fetch('/api/admin/pending-users')
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Failed to fetch data, status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setUsers(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching pending users:", error);
                setLoading(false);
            });
    }, []);

    // Handle approving a user
    const approveUser = (id: number) => {
        fetch(`/api/admin/approve-user/${id}`, {
            method: 'POST',
        })
        .then(() => {
            alert("User approved successfully!");
            setUsers(users.filter(user => user.id !== id));
        })
        .catch((error) => {
            console.error("Error approving user:", error);
        });
    };

    // Handle rejecting a user
    const rejectUser = (id: number) => {
        fetch(`/api/admin/reject-user/${id}`, {
            method: 'POST',
        })
        .then(() => {
            alert("User rejected successfully!");
            setUsers(users.filter(user => user.id !== id));
        })
        .catch((error) => {
            console.error("Error rejecting user:", error);
        });
    };

    // Open user details modal
    const handleRowClick = (user: User) => {
        setSelectedUser(user);
        setShowDetails(true);
    };

    // Close user details modal
    const closeDetailsModal = () => {
        setShowDetails(false);
    };

    return (
        <div className="admin-container">
            <h1>Admin Portal</h1>
            {loading ? (
                <p>Loading users...</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Organization</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id} onClick={() => handleRowClick(user)}>
                                <td>{user.firstname} {user.lastname}</td>
                                <td>{user.email}</td>
                                <td>{user.organization}</td>
                                <td>
                                    <button className="approve" onClick={(e) => { e.stopPropagation(); approveUser(user.id); }}>Approve</button>
                                    <button className="reject" onClick={(e) => { e.stopPropagation(); rejectUser(user.id); }}>Reject</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

             {/* User Details Modal */}
             {showDetails && selectedUser && (
                <div className="user-details-modal">
                    <div className="modal-content">
                        <span className="close-btn" onClick={closeDetailsModal}>X</span>
                        <h3>User Details</h3>
                        <p><strong>Name:</strong> {selectedUser.firstname} {selectedUser.lastname}</p>
                        <p><strong>Email:</strong> {selectedUser.email}</p>
                        <p><strong>Organization:</strong> {selectedUser.organization}</p>
                        <p><strong>Role:</strong> {selectedUser.role}</p>  
                        <p><strong>Status:</strong> {selectedUser.verified ? 'Verified' : 'Not Verified'}</p>
                        <p><strong>Phone Number:</strong> {selectedUser.phoneNumber}</p>
                        <p><strong>Address:</strong> {selectedUser.street}, {selectedUser.city}, {selectedUser.state}, {selectedUser.zipcode}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminPortal;
