import React from 'react';

const CheckEmailPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Check Your Email</h1>
            <p>We’ve sent a verification email to your inbox. Please verify your email address before proceeding.</p>
        </div>
    );
};

export default CheckEmailPage;
