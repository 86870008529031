import log from "../../assets/images/logo.svg";
import userIcon from "../../assets/icons/user.svg";
import './appHeader.styles.scss';
import {Searchbar} from "../searchbar/Searchbar";
import {useSessionManager} from "../../hooks/UseSessionManager";
import React, {useContext, useEffect, useState} from "react";
import {useApiClient} from "../../network/client/UseApiClient";
import {UserRole, UsersModel} from "../../models/UsersModel";
import {AccountDetailApi} from "../../services/AccountService";
import {NetworkState} from "../../network/dto/ApiContract";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {getUserRoleLabel} from "../../utils/AppUtils";
import {NotificationModal} from "../notifications/NotificationModal";
import {TabMenuItem} from "../tab/TabMenuItem";
import dashboardIcon from "../../assets/icons/dashboard.png";
import {matchPath, useLocation} from "react-router";
import manageIcon from "../../assets/icons/manage.png";
import {DefaultProjectContext} from "../../hooks/DefaultProject";

export const AppHeader = () => {
    const {session, chatUser, setChatUser, createSession, setUser, clearSession} = useSessionManager();
    const [user, userNetworkState, error, userData] = useApiClient<UsersModel>();
    const [show, setShow] = useState<boolean>(false);
    const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false)
    const navigate = useNavigate();
    const path = useLocation();
    const [activeMenu, setActiveMenu] = useState<string>("");
    const [showChat, setShowChat] = useState<boolean>(false);
    const dps = useContext(DefaultProjectContext);
    const [showSetDefaultProjectGuide, setShowSetDefaultProjectGuide] = useState<boolean>(false)

    /*useEffect(() => {
        if (session && chatUser)
            userData(LoginUser(session?.user?.id||'')).then((res:any)=>{
                setUser(res.fullName)
                console.log(res.fullName)
         })
    }, [session]);*/

    // useEffect(() => {
    //     {console.log("Organization ID From App Header:", user?.organization?.id)}; // Debugging log
        
    //     if (user?.organization?.id == '1') {
    //       {console.log("User is an admin")}; // Log if user is an admin
    //     }else{
    //         {console.log("Didnt go in if statement")};
    //     }
    //   }, [user]);

    useEffect(() => {
        userData(AccountDetailApi())
    }, []);

    useEffect(() => {
        if (userNetworkState === NetworkState.Loaded) {
            setChatUser({
                quivrId: user?.quivrId || '',
                user_name: user?.fullname || '',
                brainId: user?.organization?.brainId || '',
                role: user?.role || UserRole.Member
                
            });
        }
    }, [userNetworkState])


    return <header className={` bg-white sticky-top`}>
        <div className={`appHeader`}>
            <div className={`col align-self-center`}>
                <img src={log} className={`logo`} alt={`_logo`}/>
            </div>

            <div >
                <nav className="nav flex-column flex-sm-row nav-tabs my-3">
                    <TabMenuItem icon={dashboardIcon} label={'Dashboard'} pathname={'/'}
                                 isActive={!!matchPath("/", path.pathname)} />
                    <TabMenuItem icon={manageIcon} label={'Manage'} pathname={'/manage'}
                                 isActive={!!matchPath("/manage/*", path.pathname)}/>
                    {/*<TabMenuItem icon={procureIcon} label={'Procure'} pathname={'/procure'}
                             isActive={!!matchPath("/procure*", path.pathname)}/>
                <TabMenuItem icon={marketplaceIcon} label={'Marketplace'} pathname={'/marketplace'}
                             isActive={!!matchPath("/marketplace*", path.pathname)}/>*/}
                </nav>
                {/*<div className={`btnChat`} onClick={()=> {
                    if (dps.project || dps.defaultProject)
                        setShowChat(prev => !prev);
                    else
                        setShowSetDefaultProjectGuide(true)
                }}>
                    <img src={chatButtonBg}/>
                    <label className={`cursor-pointer`}>{showChat?<span className={`bi bi-x-lg`} />:'Chat'}</label>
                </div>*/}
            </div>


            <div>
                {user?.organization?.id == '1' && ( // Check if orgId is a number, not a string.
                    <div className="me-4">
                        <a href="/admin" className="text-dark">
                            <small>Pending Users</small>
                        </a>
                    </div>
                )}
            </div>

            


            <div className="d-flex align-items-start d-flex menuBar col justify-content-end">
                <div className={`align-self-center d-flex align-items-center`}>
                    <div className={`me-2`}><span className={`bi bi-bell text-dark fs-4 cursor-pointer`} onClick={()=> {
                        setShowNotificationModal(true)
                    }} /></div>
                    <div className={`me-4 small`}>{user?.organization?.name}</div>
                </div>

                <Dropdown className={'align-self-center'}>
                    <Dropdown.Toggle id="dropdown-basic"
                                     className="p-0 bg-transparent border-0 d-flex text-black dropDown_toggle align-items-center">
                        <img src={userIcon} className={`icon me-1`} />
                        <small>Hello, {user?.fullname?.split(" ")[0] || 'Unknown'} ({getUserRoleLabel(user?.role || UserRole.Unknown)})</small>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropDown_menu bg-white w-100 shadow border-white">
                        <Dropdown.Item className={'border-bottom'} onClick={() => navigate(`/settings/users`)
                        }><i className="bi bi-people fs-6 me-2"></i>Admin Settings</Dropdown.Item>
                        {/*<Dropdown.Item className={'border-bottom'} onClick={() => navigate(`/adminSettings`)*/}
                        {/*}><i className="bi bi-wrench fs-6 me-2"></i>Admin Settings</Dropdown.Item>*/}

                        <Dropdown.Item onClick={(e) => {
                            clearSession();
                            navigate('/login');
                        }}><i className="bi bi-box-arrow-right fs-6 me-2"></i>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <NotificationModal show={showNotificationModal} onHide={()=> {
                setShowNotificationModal(false)
            }} />
        </div>
        

    </header>
}