import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Registration.css';

const RegisterPage = () => {
    
    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState({
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        organization: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        password: '',
        confirmPassword: '',
    });

    const [formData, setFormData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        organization: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        password: '',
        confirmPassword: '',
        jobTitle: '',
        termsAccepted: true,
    });

    const [showTerms, setShowTerms] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        console.log(`Field updated: ${name} = ${type === 'checkbox' ? checked : value}`);
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Form data at submission:', formData);
    
        let errors = {
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            phoneNumber: '',
            organization: '',
            street: '',
            city: '',
            state: '',
            zipcode: '',
            password: '',
            confirmPassword: '',
        };
        let hasError = false;
    
        // Validation logic
        if (!formData.username.trim()) {
            errors.username = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN USERNAME');
        }
        if (!formData.firstname.trim()) {
            errors.firstname = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN FIRST NAME');
        }
        if (!formData.lastname.trim()) {
            errors.lastname = "Field cannot be empty";
            hasError = true;
        }
        if (!formData.email.trim()) {
            errors.email = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN LAST NAME');
        }
        if (!formData.organization.trim()) {
            errors.organization = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN ORGANIZATION');
        }
        
        if (!formData.password.trim()) {
            errors.password = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN PASSWORD');
        }
        if (!formData.confirmPassword.trim()) {
            errors.confirmPassword = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN CONFIRM PASSWORD');
        }
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
            hasError = true;
            console.log('ERROR IN PASSWORD DONT MATCH');
        }
        if (formData.email && !formData.email.includes('@')) {
            errors.email = "Please enter a valid email address";
            hasError = true;
            console.log('ERROR IN EMAIL');
        }
    
        console.log('Validation errors:', errors);
        console.log('Has validation error:', hasError);
        setFormErrors(errors);
        
        if (hasError) {
            console.log("Validation failed, stopping submission.");
            return;
        }

        console.log('NO ERRORS Submitting form data:', formData);
        console.log('NO ERRORS Submitting form data:', formData);
        try {
            console.log("Sending data to server:", formData);
            const response = await fetch('http://localhost:8081/api/public/registration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // If registration was successful, navigate to the next page
                console.log("Form submission successful.");
                navigate('/check-email');
            } else {
                // If the response is not okay, handle the error
                const data = await response.json();
                console.error("Form submission failed:", data);

                // You can display the error message to the user
                alert(data.message || "An error occurred while submitting the form.");
            }
        } catch (error) {
            console.error("Error during fetch:", error);
            alert("This username is already taken, or the email is awaiting approval.");
        }
    };

    const handleShowTerms = () => setShowTerms(true);
    const handleCloseTerms = () => setShowTerms(false);

    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="registerPanel">
                <h4 style={{fontWeight: '500'}} className={`text-center text-primary`}>Login | Dodda-web Portal</h4>
                <form className="mt-4" onSubmit={handleSubmit}>
                    {/* Required Fields Section */}
                    <div className="formSection">

                        <div className="formRow">
                            <div className="form-group">
                                <label htmlFor="username" className="formLabel">Username *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.username && <div className="text-danger">{formErrors.username}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="firstname" className="formLabel">First Name *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstname"
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.firstname && <div className="text-danger">{formErrors.firstname}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname" className="formLabel">Last Name *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastname"
                                    name="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.lastname && <div className="text-danger">{formErrors.lastname}</div>}
                            </div>
                        </div>
                        <div className="formRow">
                            <div className="form-group">
                                <label htmlFor="email" className="formLabel">Email *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="formLabel">Password *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.password && <div className="text-danger">{formErrors.password}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className="formLabel">Confirm Password *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.confirmPassword && <div className="text-danger">{formErrors.confirmPassword}</div>}
                            </div>
                        </div>
                        <div className="formRow">
                            <div className="form-group">
                                <label htmlFor="organization" className="formLabel">Organization *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="organization"
                                    name="organization"
                                    value={formData.organization}
                                    onChange={handleChange}
                                    required
                                />
                                {formErrors.organization && <div className="text-danger">{formErrors.organization}</div>}
                            </div>
                        </div>
                    </div>

                    <hr className={`mb-4`} style={{color: '#BBB'}}/>
    
                    {/* Optional Fields Section */}
                    <div className="row row-cols-3 g-3">
                        <div className="form-group">
                            <label htmlFor="phoneNumber" className="formLabel">Phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="street" className="formLabel">Street</label>
                            <input
                                type="text"
                                className="form-control"
                                id="street"
                                name="street"
                                value={formData.street}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city" className="formLabel">City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="state" className="formLabel">State</label>
                            <input
                                type="text"
                                className="form-control"
                                id="state"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="formRow">
                            <div className="form-group">
                                <label htmlFor="zipcode" className="formLabel">Zipcode</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="zipcode"
                                    name="zipcode"
                                    value={formData.zipcode}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
    
                    {/* Submit Button */}
                    <div className="formActions">
                        <button type="submit" className="btn btn-primary submitButton">Register</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;
