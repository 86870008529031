import {ChangeEvent, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ProjectContext} from "../../../../context/ProjectContext";
import {DefaultProjectContext} from "../../../../hooks/DefaultProject";
import {Accordion, AccordionCollapse} from "react-bootstrap";

export const ProjectSidebar = () => {
    const {projects, activeProjectId, setActiveProject} = useContext(ProjectContext);
    const navigate = useNavigate();
    const {activeBrains, setActiveBrains} = useContext(DefaultProjectContext);
    const [activeKey, setActiveKey] = useState<string>('')

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        // let checked = evt.target.checked;
        let brainId = evt.target.value;
        const selectedBrains = [...activeBrains];
        const idx = selectedBrains.findIndex(itm => itm === brainId);
        if (idx > -1) {
            selectedBrains.splice(idx, 1);
        } else {
            selectedBrains.push(brainId);
        }

        // if (selectedBrains.length === 1)
        //     navigate("/projects/"+)
        if (setActiveBrains)
            setActiveBrains(selectedBrains);
    }

    const isProject = (brainId: string | undefined): boolean => {
        return !!brainId;
    }
    const handleOpen = (key: string) => {
        setActiveKey(key);
    };

    const handleClose = () => {
        setActiveKey('');
    };
    return <div className={`w-100 h-100 p-3`}>
        <label><i className="bi bi-pencil-square me-3"/>Search Scope</label>
        <hr className={`mt-1`}/>

        <Accordion activeKey={activeKey}>
            <div className={`d-flex`}>
                <div className={`input-group`}>

                    <input className="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"
                           onChange={(evt) => {
                               const _projects: (string)[] = projects.map(itm => itm.brainId).filter(isProject) as string[];
                               if (setActiveBrains)
                                   (evt.target.checked == true) ? setActiveBrains(_projects) : setActiveBrains([])
                           }} checked={activeBrains.length === projects.length}/>

                    <Accordion.Item eventKey={'project_list'} className={"form-check border-0 px-1"} onClick={()=> activeKey?handleClose():handleOpen('project_list')}>
                        <span className={`cursor-pointer`}>Select a project</span>
                    </Accordion.Item>
                </div>
                <span className={`bi ${activeKey?'bi-chevron-down':'bi-chevron-right'}`}/>
            </div>

                <Accordion.Collapse eventKey={'project_list'} >
                    <div className={`border px-3 py-3 rounded mt-3`}>
                        {projects?.map(project => (<div className="form-check">
                            <input className="form-check-input" type="checkbox" name={`project_ids`}
                                   value={project.brainId}
                                   checked={activeBrains.findIndex(itm => itm === project.brainId) > -1}
                                   id={project.id} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor={project.id}>
                                {project.name}
                            </label>
                        </div>))}
                    </div>
                </Accordion.Collapse>
                {/*<select className={`form-control projectDropdown`} onChange={handleChange} name={`def_project`} value={activeProjectId}>
                <option defaultChecked value={''} disabled>Select Projects</option>

            </select>*/}

        </Accordion>

    </div>;
}