import {Route, Routes, useLocation} from "react-router";
import {LoginPage} from "./login/Login";
import  RegisterPage from "./registration/Registration";
import CheckEmailPage from "./registration/CheckEmail";
import VerifyEmailPage from "./registration/VerifyEmail";
import RequestSentPage from './registration/RequestSent';
import {ManagementConsolePage} from "./management-console/manageMentConsolePage/ManagementConsolePage";
import {AuthGuard} from "../components/auth/AuthGuard";
import {v4} from "uuid";
import AdminPortal from "../components/adminPortal/AdminPortal";  
import {AppHeader} from "../components/header/AppHeader";
import {ChatPage} from "./chatroom/ChatPage";
import {ResetPasswordRequest} from "./login/resetpassword/ResetPasswordRequest";
import {ResetPassword} from "./login/resetpassword/ResetPassword";
import PdfRenderer from "../components/pdf/PdfRenderer";
import { Viewer } from "./viewer/Viewer";
import {ForgeView} from "./viewer/ForgeView";
import {ModelChecker} from "../components/modelchecker/ModelChecker";
import { useEffect } from "react";
import { logPageView } from "../utils/ganalytics";

const AnalyticsTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(`GA Metric for path ${location.pathname}`);
    logPageView(location.pathname);
  }, [location]);

  return null;
};

export const Index = () => {
    return <div className={`h-100`}>
        <AnalyticsTracker />
        <Routes>
            <Route path={'/login'} element={<LoginPage />} />
            <Route path={'/model'} element={<ModelChecker />} />
            <Route path={'/viewer'} element={<Viewer />} />
            <Route path={'/fv'} element={<ForgeView />} />
            <Route path="/admin" element={<AdminPortal />} />
            <Route path="/check-email" element={<CheckEmailPage />} />
            <Route path="/verify-email" element={<VerifyEmailPage />} />
            <Route path={'/pdf'} element={<PdfRenderer url={`https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK`} highlight={`This is an Internet Standards Track document.
This document is a product of the Internet Engineering Task Force
(IETF). It represents the consensus of the IETF community. It has
received public review and has be`} />} />
            <Route path={'/reset-password'} element={<ResetPasswordRequest />} />
            <Route path={'/reset-password/:uid'} element={<ResetPassword />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/request-sent" element={<RequestSentPage />} />
            <Route path={'/chatroom/:chatId'} element={<AuthGuard to={'/login'} element={<div className={`h-100 d-flex flex-column`}>
                <AppHeader />
                <div className={`h-100 flex-fill overflow-hidden`}>
                    <ChatPage />
                </div>
            </div>} key={v4()} /> } />
            <Route loader={async ()=> {}} path={'/*'} element={<AuthGuard to={'/login'} element={<ManagementConsolePage />} key={v4()} /> } />
        </Routes>
    </div>
}