import searchIcon from "../../assets/icons/search.svg";
import "./searchbar.styles.scss";
import React, {useState} from "react";

export const Searchbar = (props: {onSearch: (query: string)=> void}) => {
    const [query, setQuery] = useState<string>("");
    return <form className={`input-group input-group-sm searchbar col`} onSubmit={(evt)=> {evt.preventDefault(); props.onSearch(query)}} onClick={()=> props.onSearch("")}>
        <span className="input-group-text p-1 border-start-0 bg-white pe-3 shadow-sm" id="basic-addon2"><span
            className={`searchIcon`}><img src={searchIcon}/></span></span>
        <input type="search" className="form-control ps-3 shadow-sm" style={{pointerEvents: 'none'}}
               placeholder="Search" disabled
               aria-describedby="basic-addon2" value={query} onChange={(evt)=> {
                   setQuery(evt.target.value)
        }}/>

    </form>
}