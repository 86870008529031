import {Fragment, useContext, useEffect, useState} from "react";
import {Chatroom} from "./Chatroom";
import {useSessionManager} from "../../hooks/UseSessionManager";
import {useApiClient} from "../../network/client/UseApiClient";
import {InitiateChat} from "../../services/ChatService";
import moment from "moment";
import {ChatInitiationResponse} from "../../models/ChatModel";
import {useToast} from "../../components/toast/ToastManager";
import {v4} from "uuid";
import {NetworkState} from "../../network/dto/ApiContract";
import {DefaultProjectContext} from "../../hooks/DefaultProject";
import logo from "../../assets/images/logo.svg";

export const ChatModal = (props: {onClose: ()=> void}) => {
    const [activeChatId, setActiveChatId] = useState<string>();
    // const [userProject, userProjectState, userProjectError, fetchUserProject] = useApiClient<Project>();
    const {chatId, chatUser, setChatId, isChatSessionValid, resetChatTimestamp, clearChatSession} = useSessionManager();
    const [chatIdRes, initiateChatState, initiateChatError, initiateChat] = useApiClient<ChatInitiationResponse>();
    const toast = useToast();
    const dps = useContext(DefaultProjectContext);
    const [brainIds, setBrainIds] = useState(dps.activeBrains)
    let tf: NodeJS.Timeout;


    useEffect(() => {
        console.log("chat user: ", chatUser)
        if ((chatId === null || !isChatSessionValid()) && chatUser && chatUser.quivrId && (dps.activeBrains.length > 0)) {
            //TODO: Initiate Chat
            initNewChatSession();
        } else if (chatId) {
            resetChatTimestamp();
            setActiveChatId(chatId);
        } else if (!dps.defaultProject) {} else {
            tf = setTimeout(()=> {
                toast.show({id: v4(), title: 'Quivr not configured for this.', isSuccess: false, duration: 3000});
                props.onClose();
            }, 700)
        }
        return ()=> {
            if (tf) {
                clearTimeout(tf)
            }
        }
    }, [chatId]);

    const initNewChatSession = () => {
        if ((chatId === null || !isChatSessionValid()) && chatUser && chatUser.quivrId && (dps.activeBrains.length > 0)) {
            initiateChat(InitiateChat({
                name: chatUser?.user_name||`Belfour Beatty Chat ${moment().format('DD/mm/yy HH:MM')}`,
                user_id: chatUser?.quivrId||'',
            })).then(res=> {
                setChatId(res.chat_id)
                setActiveChatId(res.chat_id)
            });
        }
    }

    return (initiateChatState === NetworkState.Loading ) && (dps.activeBrains.length > 0)? <div className={`w-100 h-100 bg-white d-flex justify-content-center align-items-center`} style={{minHeight:'80%'}}>
        <h4 className={`text-secondary`}>Initializing Chat...</h4>
    </div>:<Fragment>
        {activeChatId ? <Chatroom brainId={brainIds.length>0?brainIds[0]:''} chatId={activeChatId} onHide={()=>{
            props.onClose()
        }} onNewChat={()=> {
            setActiveChatId(undefined);
            clearChatSession();
            initNewChatSession();
        }}/> : <div className={`w-100 h-100 bg-white d-flex justify-content-center align-items-center`}>
            <h4 className={`text-danger`}>{dps.project?'Something went wrong...':'Please set a default project or choose a project first.'}</h4>
        </div>}
        <div
        className={`w-100 bg-white d-flex justify-content-end align-items-center pe-3 pb-1 pt-1 text-secondary`} style={{fontSize: '11px'}}><img src={logo} style={{width: '16px', height: '16px'}} className={`me-2`}/>Powered by Dodda</div>
    </Fragment>
}