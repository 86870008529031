import {Route, Routes, useLocation} from "react-router";
import {McDashboardPage} from "../dashboard/McDashboardPage";
import {McManagePage} from "../manage/McManagePage";
import {McProcurePage} from "../procure/McProcurePage";
import {McMarketplacePage} from "../marketplace/McMarketplacePage";
import './manageConsole.scss';
import {AppHeader} from "../../../components/header/AppHeader";
import React, {useContext, useEffect, useState} from "react";
import {ProjectDetailPage} from "../manage/project/details/ProjectDetailPage";
import Users from "../../manageUsers/Users";
import {Modal} from "react-bootstrap";
import {AddNewProject} from "../manage/project/addNewProject/AddNewProject";
import {ChatModal} from "../../chatroom/ChatModal";
import AdminSettings from "../../adminSettings/AdminSettings";
import {SetDefaultProjectGuideModal} from "../../../components/guide/SetDefaultProjectGuideModal";
import {DefaultProjectContext} from "../../../hooks/DefaultProject";
import {Searchbar} from "../../../components/searchbar/Searchbar";
import {ProjectSidebar} from "../manage/sidebar/ProjectSidebar";
import {ProjectContext, ProjectProvider} from "../../../context/ProjectContext";

export const ManagementConsolePage = () => {
    const path = useLocation();
    const [activeMenu, setActiveMenu] = useState<string>("");
    const [showChat, setShowChat] = useState<boolean>(false);
    const dps = useContext(DefaultProjectContext);
    const [showSetDefaultProjectGuide, setShowSetDefaultProjectGuide] = useState<boolean>(false)

    useEffect(()=> {
        if (path) {
            setActiveMenu(path.pathname)
            setShowChat(false)
        }
    }, [path])

    return <div className={`d-flex flex-column h-100`}>
        <AppHeader/>
        <div className={`position-relative d-flex justify-content-center border-bottom bg-white py-3 z-1`}>
            <div className={`align-self-center w-50`}>
                <Searchbar onSearch={(query)=> {
                    if (dps.activeBrains.length > 0)
                        setShowChat(prev => !prev);
                    else
                        setShowSetDefaultProjectGuide(true)
                }} />
            </div>
        </div>

        <ProjectProvider>
            <div className={`flex-fill bg-body pageContainer`}>
                <div className={`sidebar`}>
                    <ProjectSidebar  />
                </div>
                <main>
                    <Routes>
                        <Route path={'/'} element={<McDashboardPage/>}/>
                        <Route path={'/manage'} element={<McManagePage/>}/>
                        <Route path={'/manage/project/addProject'} element={<AddNewProject/>}/>
                        <Route path={'/manage/project/edit-project/:projectId'} element={<AddNewProject/>}/>
                        <Route path={'/manage/project/:projectId/*'} element={<ProjectDetailPage/>} />
                        <Route path={'/procure'} element={<McProcurePage/>}/>
                        <Route path={'/marketplace'} element={<McMarketplacePage/>}/>
                        <Route path={'/users'} element={<Users/>}/>
                        <Route path={'/settings/*'} element={<AdminSettings/>}/>
                    </Routes>
                </main>
            </div>
        </ProjectProvider>

        <Modal className={`overflow-hidden border-0 shadow-sm modal modal-xl vh-100 `} show={showChat} onHide={()=> {setShowChat(false)}}><ChatModal onClose={()=> setShowChat(false)} /></Modal>
        <SetDefaultProjectGuideModal show={showSetDefaultProjectGuide} onHide={()=> setShowSetDefaultProjectGuide(false)} />
    </div>;
}