import {createContext, Fragment, useEffect, useState} from "react";
import {Project} from "../models/Project";
import {NetworkState} from "../network/dto/ApiContract";
import {getProjects} from "../services/ProjectService";
import {useApiClient} from "../network/client/UseApiClient";
import {useLocation, useMatch, useParams} from "react-router";

export interface ProjectContextState {
    activeProjectId: string,
    activeBrains: string[],
    projects: Project[],
    projectNetworkState?: NetworkState,
    setActiveProject?: (projectId: string)=> void
    setActiveBrains?: (brainIds: string[])=> void
}

export const ProjectContext = createContext<ProjectContextState>({activeProjectId: '', activeBrains: [], projects: []})

export const ProjectProvider = ({children}: {children: any}) => {
    const [projects, projectNetworkState, error, fetchProjects] = useApiClient<Project[]>();
    const [activeProject, setActiveProject] = useState<string>('');
    const [activeBrains, setActiveBrains] = useState<string[]>([]);
    const match = useMatch('/manage/project/:id/documents');
    const onSetActiveProject = (projectId: string) => {
        setActiveProject(projectId)
    }

    const onSetActiveBrains = (brainIds: string[]) => {
        setActiveBrains(brainIds)
    }
    useEffect(()=> {
        fetchProjects(getProjects());
    }, [])
    useEffect(()=> {
        if (match?.pathname) {
            setActiveProject(match.params.id||'')
        } else {
            setActiveProject('')
        }
        // alert(params.pathname)
        // setActiveProject(params.projectId)
    }, [match])
    return <ProjectContext.Provider value={{activeBrains: activeBrains, activeProjectId: activeProject, projects: projects||[], projectNetworkState: projectNetworkState, setActiveProject: onSetActiveProject, setActiveBrains: onSetActiveBrains}}>{children}</ProjectContext.Provider>
}