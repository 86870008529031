import React from "react";
import { useNavigate } from "react-router-dom";

const RequestSentPage = () => {
  const navigate = useNavigate();

  const handleReturnToLogin = () => {
    navigate('/login');
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Account Request Sent</h1>
      <p>Your request to create an account has been sent. Please wait for approval.</p>
      <button 
        onClick={handleReturnToLogin} 
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer"
        }}
      >
        Return to Login
      </button>
    </div>
  );
};

export default RequestSentPage;